

import { Inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';
import { Locale } from 'vue-i18n';

import FEATURES from '@/modules/common/constants/features.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import HorizontalArrows from '@/modules/common/components/ui-kit/horizontal-arrows.vue';
import MonthPicker from '@/modules/common/components/ui-kit/month-picker.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import type Month from '@/modules/common/types/month.type';
import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';
import CurrencySwitcherTooltip from '@/modules/common/components/currency-switcher-tooltip.vue';

@Component({
    components: {
        MonthPicker,
        CurrencySwitcher,
        HorizontalArrows,
        CurrencySwitcherTooltip,
    },
})
export default class DiLiteHeader extends Vue {
    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    changeMonth(diff: number) {
        const date = new Date(this.date);
        date.setMonth(date.getMonth() + diff);
        this.date = date;
    }

    get date(): Date {
        const { month, year } = this.documentFiltersService.storeState.settings;
        return new Date(year, month, 1);
    }

    set date(date: Date) {
        this.documentFiltersService.saveMonth(date.getMonth() as Month);
        this.documentFiltersService.saveYear(date.getFullYear());
    }

    get locale(): Locale {
        return this.$i18n.locale;
    }

    get hotelName() {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }

    get feature() {
        return FEATURES.LITE_DI;
    }

    get isHotelUser() {
        return this.$route.path.split('/')[1] === 'hotel';
    }

    get backRoute() {
        const branch = this.$route.name!.split('.')[0];

        return {
            name: `${branch}.di-lite`,
        };
    }
}
