

import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { ChartOptions } from 'chart.js';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';
import RootCauseAnalysisTooltip from './root-cause-analysis-tooltip.vue';

@Component({
    components: {
        CustomGraph,
        RootCauseAnalysisTooltip,
        CustomTooltip,
    },
})
export default class RootCauseAnalysisGraph extends Vue {
    @Prop({
        required: true,
        type: null as unknown as undefined,
        default: () => null,
    })
    public data!: { [name: string]: number } | null;

    @Prop({
        required: true,
        type: Boolean,
    })
    public loading!: boolean;

    get chartData() {
        return {
            datasets: [{
                data: this.data ? Object.values(this.data) : null,
                backgroundColor: this.colors,
                hoverOffset: 4,
            }],
        };
    }

    get options(): ChartOptions {
        return {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: 4,
            },
            legend: {
                display: false,
            },
        };
    }

    get legend() {
        return this.data ? Object.keys(this.data) : null;
    }

    get colors() {
        return [
            'rgb(82,169,255)',
            'rgb(161,162,255)',
            'rgb(92,213,214)',
            'rgb(191,29,143)',
            'rgb(0,117,158)',
            'rgb(20,81,103)',
            'rgb(0,26,51)',
            'rgb(162, 127, 239)',
            'rgb(213, 130, 15)',
            'rgb(36, 75, 137)',
            'rgb(195, 76, 48)',
            'rgb(211, 213, 18)',
            'rgb(103, 86, 210)',
            'rgb(37, 185, 150)',
            'rgb(44, 168, 176)',
            'rgb(11, 75, 64)',
            'rgb(191, 153, 118)',
            'rgb(3, 82, 241)',
            'rgb(201, 211, 124)',
            'rgb(157, 5, 25)',
            'rgb(208, 222, 154)',
            'rgb(216, 103, 208)',
        ];
    }
}
